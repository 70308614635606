import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Customer, Contact } from '@webcoffee/interfaces';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';

@Component({
    selector: 'webcoffee-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, DxSelectBoxModule],
})
export class ContactFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() contact!: Contact | null;
    @Input() customerId!: string | null;
    @Output() contactOutput: EventEmitter<FormGroup> = new EventEmitter();

    subs = new SubSink();

    contactFormGroup: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        email: new FormControl('', Validators.email),
        phone: new FormControl('', Validators.required),
        customerId: new FormControl(''),
    });
    customerOptions!: { label: string; value: string }[];
    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        this.http
            .get<Customer[]>('api/customers')
            .pipe(take(1))
            .subscribe((customers) => {
                this.customerOptions = customers.map((customer) => ({ label: `${customer.firstname} ${customer.lastname}`, value: customer.id as string }));
            });
        this.subs.sink = this.contactFormGroup.valueChanges.pipe(debounceTime(250)).subscribe((values) => this.contactOutput.emit(this.contactFormGroup));
    }

    ngOnChanges() {
        if (this.contact) {
            this.contactFormGroup.patchValue({ ...this.contact, customerId: this.contact.customer?.id });
        }
        if (this.customerId) {
            this.contactFormGroup.patchValue({ customerId: this.customerId });
        }
    }

    get nameControl(): AbstractControl | null {
        return this.contactFormGroup.get('name');
    }

    get emailControl(): AbstractControl | null {
        return this.contactFormGroup.get('email');
    }

    get phoneControl(): AbstractControl | null {
        return this.contactFormGroup.get('phone');
    }

    get customerIdControl(): AbstractControl | null {
        return this.contactFormGroup.get('customerId');
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
