import { Pipe, PipeTransform } from '@angular/core';
import { Notification, NotificationSectionEnum, NotificationTypeEnum } from '@webcoffee/interfaces';

@Pipe({ name: 'notificationType', standalone: true })
export class NotificationTypePipe implements PipeTransform {
    transform(notification: Notification) {
        let message = '';
        if ([NotificationSectionEnum.Order, NotificationSectionEnum.OrderPaid].indexOf(notification.section) !== -1) {
            message = 'Comanda';
            if (notification.type === NotificationTypeEnum.Create) return `${message} creata`;
            if (notification.type === NotificationTypeEnum.Upgrade) return `${message} modificata`;
            if (notification.type === NotificationTypeEnum.Delete) return `${message} stearsa`;
            if (notification.type === NotificationTypeEnum.Analyze) return `${message} analizata`;
        }
        if ([NotificationSectionEnum.Invoice, NotificationSectionEnum.InvoiceByCard].indexOf(notification.section) !== -1) {
            message = 'Factura';
            if (notification.type === NotificationTypeEnum.Create) return `${message} creata`;
            if (notification.type === NotificationTypeEnum.Upgrade) return `${message} modificata`;
            if (notification.type === NotificationTypeEnum.Delete) return `${message} stearsa`;
            if (notification.type === NotificationTypeEnum.Analyze) return `${message} analizata`;
        }
        if (notification.section === NotificationSectionEnum.Product) {
            message = 'Produs';
            if (notification.type === NotificationTypeEnum.Create) return `${message} creat`;
            if (notification.type === NotificationTypeEnum.Upgrade) return `${message} modificat`;
            if (notification.type === NotificationTypeEnum.Delete) return `${message} sters`;
            if (notification.type === NotificationTypeEnum.Analyze) return `${message} analizat`;
        }
        return message;
    }
}
