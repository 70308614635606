import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Observable, combineLatest, distinctUntilChanged, filter, map, tap, timer } from 'rxjs';
import { SubSink } from 'subsink';

import { AppStore } from './app.store';
import { LoadingComponent } from './shared/ui/loading/loading.component';
import { RouterStore } from './shared/utils/services/router.store';
import { User, WebcoffeeLinks, WebcoffeeRoutes } from '@webcoffee/interfaces';
import { SocketClientService } from './shared/data-access/socket-client.service';
import { Settings, Zone } from 'luxon';
import { io } from 'socket.io-client';

@Component({
    selector: 'webcoffee-admin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, LoadingComponent, RouterModule, PushPipe],
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        private appStore: AppStore,
        private routerStore: RouterStore,
        private router: Router,
        private route: ActivatedRoute,
        private socketClient: SocketClientService,
    ) {}

    user$: Observable<User | null> = this.appStore.getUser;
    url$: Observable<string> = this.routerStore.getUrl;
    errorCode$: Observable<number | null> = this.appStore.getErrorCode;
    loading$: Observable<boolean> = this.appStore.getLoading;

    subs = new SubSink();

    links = WebcoffeeLinks;

    ngOnInit() {
        Settings.defaultZone = 'UTC';
        this.appStore.pingEffect();
        this.subs.sink = combineLatest([this.user$.pipe(distinctUntilChanged()), this.url$, this.loading$]).subscribe(([user, url, loading]) => {
            if (!loading) {
                if (user) {
                    if (url === WebcoffeeLinks.login) {
                        this.router.navigate([WebcoffeeRoutes.home]);
                    }
                    this.socketClient.connect();
                } else {
                    if (url !== WebcoffeeLinks.login) {
                        this.router.navigate([WebcoffeeRoutes.login]);
                    }
                    this.socketClient.disconnect();
                }
            }
        });
        this.routerStore.watchRouterEventsEffect(
            this.router.events.pipe(
                filter((e) => e instanceof NavigationEnd),
                map((e) => {
                    if (e instanceof NavigationEnd) {
                        this.routerStore.patchState({ url: e.url });
                    }
                    return this.route;
                }),
            ),
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
