import { ActivatedRouteSnapshot, Routes, createUrlTreeFromSnapshot } from '@angular/router';
import { WebcoffeeRoutes } from '@webcoffee/interfaces';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/feature/default-layout.component';
import { HomeComponent } from './home/feature/home.component';
import { productRoutes } from './products/products.routing';
import { ordersRoutes } from './orders/orders.routing';
import { productRequestsRoutes } from './product-requests/product-requests.routing';
import { customerRouting } from './customers/customers.routing';
import { inject } from '@angular/core';
import { AppStore } from './app.store';
import { filter, map, take } from 'rxjs';
import { CustomersStore } from './customers/data-access/customers.store';
import { OrderStore } from './orders/data-access/orders.store';
import { tasksRouting } from './tasks/tasks.routing';
import { TaskStore } from './tasks/data-access/tasks.store';
import { ProductRequestsStore } from './product-requests/data-access/product-requests.store';
import { RolesAndPermissionsStore } from './roles-and-permissions/data-access/roles-and-permissions.store';
import { BannersStore } from './banners/data-access/banners.store';
import { ProductStore } from './products/data-access/product.store';
import { providerRoutes } from './providers/providers.routing';
import { ProviderStore } from './providers/data-access/provider.store';
import { ProviderItemStore } from './providerItems/data-access/provider-item.store';
import { providerItemsRoutes } from './providerItems/provider-items.routing';
import { providerLogsRoutes } from './providerLogs/provider-logs.routing';
import { ProviderLogsStore } from './providerLogs/data-access/provider-logs.store';

export const AuthGuard = (next: ActivatedRouteSnapshot) => {
    return inject(AppStore).getUser.pipe(
        filter((user) => user && user.role?.level > 0),
        take(1),
        map((user) => {
            if (next.data?.role && next.data.role > user.role.level) {
                return createUrlTreeFromSnapshot(next, [WebcoffeeRoutes.home]);
            }
            if (user) {
                return true;
            }
            return createUrlTreeFromSnapshot(next, [WebcoffeeRoutes.login]);
        }),
    );
};

export const routes: Routes = [
    {
        path: WebcoffeeRoutes.home,
        component: DefaultLayoutComponent,
        children: [
            { path: '', component: HomeComponent },
            {
                path: WebcoffeeRoutes.users,
                loadComponent: () => import('./users/feature/users.component').then((c) => c.UsersComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.userProfile,
                loadComponent: () => import('./user-profile/feature/user-profile.component').then((c) => c.UserProfileComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.emails,
                loadComponent: () => import('./emails/feature/emails.component').then((c) => c.EmailsComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.dls,
                loadComponent: () => import('./distribution-list/feature/distribution-list.component').then((c) => c.DistributionListComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.frontPageProducts,
                loadComponent: () => import('./front-page/feature/front-page.component').then((c) => c.FrontPageComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.pages,
                loadComponent: () => import('./pages/feature/pages.component').then((c) => c.PagesComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.banners,
                loadComponent: () => import('./banners/feature/banners.component').then((c) => c.BannersComponent),
                canActivate: [AuthGuard],
                providers: [BannersStore],
            },
            {
                path: WebcoffeeRoutes.categories,
                loadComponent: () => import('./categories/feature/categories.component').then((c) => c.CategoriesComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.detailSets,
                loadComponent: () => import('./detail-sets/feature/detail-sets.component').then((c) => c.DetailSetsComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.details,
                loadComponent: () => import('./details/feature/details.component').then((c) => c.DetailsComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.redirects,
                loadComponent: () => import('./redirects/feature/redirects.component').then((c) => c.RedirectsComponent),
                canActivate: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.products,
                children: productRoutes,
                canActivateChild: [AuthGuard],
                providers: [ProductStore],
            },
            {
                path: WebcoffeeRoutes.providers,
                children: providerRoutes,
                canActivateChild: [AuthGuard],
                providers: [ProviderStore],
            },
            {
                path: WebcoffeeRoutes.providerItems,
                children: providerItemsRoutes,
                canActivateChild: [AuthGuard],
                providers: [ProviderItemStore],
            },
            {
                path: WebcoffeeRoutes.providerLogs,
                children: providerLogsRoutes,
                canActivateChild: [AuthGuard],
                providers: [ProviderLogsStore],
            },
            {
                path: WebcoffeeRoutes.orders,
                children: ordersRoutes,
                canActivateChild: [AuthGuard],
                providers: [OrderStore],
            },
            {
                path: WebcoffeeRoutes.productRequests,
                children: productRequestsRoutes,
                canActivateChild: [AuthGuard],
                providers: [ProductRequestsStore],
            },
            {
                path: WebcoffeeRoutes.customers,
                children: customerRouting,
                providers: [CustomersStore],
                canActivateChild: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.tasks,
                children: tasksRouting,
                canActivateChild: [AuthGuard],
                providers: [TaskStore],
            },
            {
                path: WebcoffeeRoutes.jobs,
                loadComponent: () => import('./jobs/feature/jobs.component').then((c) => c.JobsComponent),
                canActivateChild: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.brands,
                loadComponent: () => import('./brands/feature/brands.component').then((c) => c.BrandsComponent),
                canActivateChild: [AuthGuard],
            },
            {
                path: WebcoffeeRoutes.rolesPermissions,
                loadComponent: () => import('./roles-and-permissions/feature/roles-and-permissions.component').then((c) => c.RolesAndPermissionsComponent),
                canActivateChild: [AuthGuard],
                providers: [RolesAndPermissionsStore],
            },
            {
                path: WebcoffeeRoutes.tools,
                loadComponent: () => import('./tools/feature/tools.component').then((c) => c.ToolsComponent),
                canActivate: [
                    () => {
                        const appStore = inject(AppStore);
                        return appStore.getUser.pipe(
                            filter((user) => !!user),
                            map((user) => user.role.level >= 100),
                        );
                    },
                ],
            },
            // {
            //     path: WebcoffeeRoutes.addresses,
            //     loadChildren: () => import('./modules/addresses/addresses.module').then((m) => m.AddressesModule),
            //     canLoad: [AuthGuard],
            //     data: { breadcrumb: { label: 'Adrese' } },
            // },
            // {
            //     path: WebcoffeeRoutes.contacts,
            //     loadChildren: () => import('./modules/contacts/contacts.module').then((m) => m.ContactsModule),
            //     canLoad: [AuthGuard],
            //     data: { breadcrumb: { label: 'Contacte' } },
            // },
        ],
    },
    {
        path: WebcoffeeRoutes.login,
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadComponent: () => import('./login/feature/login.component').then((c) => c.LoginComponent),
            },
        ],
    },

    // {
    //     path: 'smartbill',
    //     loadChildren: () => import('./modules/smartbill/smartbill.module').then((m) => m.SmartbillModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'SmartBill' } },
    // },
    // {
    //     path: WebcoffeeRoutes.customers,
    //     loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Clienti' } },
    // },
    // {
    //     path: WebcoffeeRoutes.addresses,
    //     loadChildren: () => import('./modules/addresses/addresses.module').then((m) => m.AddressesModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Adrese' } },
    // },
    // {
    //     path: WebcoffeeRoutes.contacts,
    //     loadChildren: () => import('./modules/contacts/contacts.module').then((m) => m.ContactsModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Contacte' } },
    // },
    // {
    //     path: WebcoffeeRoutes.details,
    //     loadChildren: () => import('./modules/details/details.module').then((m) => m.DetailsModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Detalii' } },
    // },
    // {
    //     path: WebcoffeeRoutes.detailSets,
    //     loadChildren: () => import('./modules/detail-sets/detail-sets.module').then((m) => m.DetailSetsModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Seturi detalii' } },
    // },
    // {
    //     path: WebcoffeeRoutes.importWizard,
    //     loadChildren: () => import('./modules/import-wizard/import-wizard.module').then((m) => m.ImportWizardModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Import Wizard' } },
    // },
    // {
    //     path: WebcoffeeRoutes.products,
    //     loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Produse' } },
    // },
    // {
    //     path: WebcoffeeRoutes.orders,
    //     loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    //     canLoad: [AuthGuard],
    //     data: { breadcrumb: { label: 'Comenzi' } },
    // },
    { path: '**', redirectTo: WebcoffeeRoutes.home },
];
