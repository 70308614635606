import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { take } from 'rxjs/operators';

import { Contact } from '@webcoffee/interfaces';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ToasterService } from '../../../shared/utils/services/toastr.service';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from '../../../shared/components/contact-form/contact-form.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'webcoffee-contact-modal',
    templateUrl: './contact-modal.component.html',
    styleUrls: ['./contact-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, ContactFormComponent, MatButtonModule],
})
export class ContactModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { customerId: string; contact: Contact },
        private http: HttpClient,
        private dialogRef: MatDialogRef<ContactModalComponent>,
        private toastr: ToasterService,
    ) {}

    contact: Contact = this.data.contact;
    contactFormGroup!: FormGroup;
    customerId: string = this.data.customerId;

    onContactOutput(e: FormGroup) {
        this.contactFormGroup = e;
    }

    saveContact() {
        if (!this.data.contact) {
            this.http
                .post<Contact>('/api/contacts', this.contactFormGroup.value)
                .pipe(take(1))
                .subscribe({
                    next: (contact) => {
                        notify('Contactul a fost creat cu success!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        } else {
            this.http
                .patch<Contact>('/api/contacts/' + this.data.contact.id, { ...this.contactFormGroup.value, customerId: this.customerId })
                .pipe(take(1))
                .subscribe({
                    next: (address) => {
                        notify('Contactul a fost modificat cu success!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        }
    }
}
