import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Contact, LinkAction, NavigationLinks } from '@webcoffee/interfaces';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '../../../shared/ui/confirmation-dialog/confirmation-dialog.component';
import { PageNavigatorComponent } from '../../../shared/ui/page-navigator/page-navigator.component';
import { CustomersStore } from '../../data-access/customers.store';
import { ContactModalComponent } from '../../ui/contact-modal/contact-modal.component';

@Component({
    selector: 'webcoffee-customer-contacts',
    templateUrl: './customer-contacts.component.html',
    styleUrls: ['./customer-contacts.component.scss'],
    standalone: true,
    imports: [CommonModule, PageNavigatorComponent, MatCardModule, MatIconModule, PushPipe, MatButtonModule],
})
export class CustomerContactsComponent implements OnInit {
    constructor(private route: ActivatedRoute, private store: CustomersStore, private dialog: MatDialog) {}

    contacts$: Observable<Contact[]> = this.store.getCustomerContacts;
    navigationLinks: NavigationLinks = {
        left: [
            {
                link: ['..'],
                text: 'Inapoi',
                icon: 'caret-left',
            },
        ],
        right: [
            {
                icon: 'plus',
                emit: LinkAction.ADD,
                classes: 'accent',
            },
        ],
    };

    ngOnInit(): void {
        this.store.watchRouteParamsEffect(this.route.params);
        this.store.getSelectedCustomer
            .pipe(
                filter((customer) => !!customer),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerContactsEffect());
    }

    onAction(e: LinkAction) {
        if (e === LinkAction.ADD) {
            this.addContact();
        }
    }

    addContact() {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(ContactModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerContactsEffect());
    }

    modifyContact(contact: Contact) {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(ContactModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                                contact,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerContactsEffect());
    }

    deleteContact(contact: Contact) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    message: 'Esti sigur ca vrei sa stergi acest contact?',
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
                if (res.confirmed) {
                    this.store.deleteContactEffect(contact.id);
                }
            });
    }
}
