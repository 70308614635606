import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Customer, NavigationLinks, WebcoffeeLinks } from '@webcoffee/interfaces';
import { map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { CustomerFormComponent } from '../../../shared/ui/customer-form/customer-form.component';
import { PageNavigatorComponent } from '../../../shared/ui/page-navigator/page-navigator.component';
import { CustomersStore } from '../../data-access/customers.store';

@Component({
    selector: 'webcoffee-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    standalone: true,
    imports: [CommonModule, PageNavigatorComponent, CustomerFormComponent, PushPipe, MatButtonModule, LetDirective],
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
    subs = new SubSink();

    constructor(private store: CustomersStore, private route: ActivatedRoute) {}

    customer$: Observable<Customer> = this.store.getSelectedCustomer;
    customerFormGroup$: Observable<FormGroup> = this.store.getCustomerFormGroup;
    webcoffeeLinks = WebcoffeeLinks;

    navigationLinks$: Observable<NavigationLinks> = this.customer$.pipe(
        map((customer) => ({
            left: [
                {
                    link: ['..'],
                    text: 'Inapoi',
                    icon: 'caret-left',
                },
            ],
            right: customer
                ? [
                      { link: ['adrese'], text: 'Adrese' },
                      { link: ['contacte'], text: 'Contacte' },
                      { link: ['date-facturare'], text: 'Date facturare' },
                  ]
                : [],
        })),
    );

    ngOnInit(): void {
        this.store.watchRouteParamsEffect(this.route.params);
    }

    onCustomerOutput(e: FormGroup) {
        this.store.patchState({ customerFormGroup: e });
    }

    saveCustomer() {
        this.store.saveCustomerEffect();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
