import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { CustomersComponent } from './feature/customers/customers.component';
import { CustomerDetailsComponent } from './feature/customer-details/customer-details.component';
import { CustomerAddressesComponent } from './feature/customer-addresses/customer-addresses.component';
import { CustomerContactsComponent } from './feature/customer-contacts/customer-contacts.component';
import { CustomerLegalInfosComponent } from './feature/customer-legal-infos/customer-legal-infos.component';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CustomersStore } from './data-access/customers.store';

export const customerRouting: Routes = [
    {
        path: '',
        loadComponent: () => import('./feature/customers/customers.component').then((c) => c.CustomersComponent),
    },
    {
        path: ':id',
        children: [
            {
                path: '',
                component: CustomerDetailsComponent,
                resolve: {
                    customer: (route: ActivatedRouteSnapshot) => {
                        const http = inject(HttpClient);
                        if (route.params.id !== 'noua') {
                            return http.get('api/customers/' + route.params.id);
                        } else {
                            return null;
                        }
                    },
                },
            },
            {
                path: 'adrese',
                component: CustomerAddressesComponent,
            },
            {
                path: 'contacte',
                component: CustomerContactsComponent,
            },
            {
                path: 'date-facturare',
                component: CustomerLegalInfosComponent,
            },
        ],
    },
];
