import { Routes } from '@angular/router';

export const productRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./feature/products/products.component').then((c) => c.ProductsComponent),
    },
    {
        path: ':id',
        loadComponent: () => import('./feature/product-details/product-details.component').then((c) => c.ProductDetailsComponent),
    },
];
