import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { take } from 'rxjs/operators';
import { Address } from '@webcoffee/interfaces';
import { ToasterService } from '../../../shared/utils/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from '../../../shared/components/address-form/address-form.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'webcoffee-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, AddressFormComponent, MatButtonModule],
})
export class AddressModalComponent implements OnInit {
    location: { lat: number; lng: number } = { lat: 44.426798047801945, lng: 26.102538399999997 };
    geocodeAddress;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { customerId: string; address: Address },
        private http: HttpClient,
        private toastr: ToasterService,
        private dialogRef: MatDialogRef<AddressModalComponent>,
    ) {}

    address: Address = this.data.address;
    addressFormGroup!: FormGroup;
    customerId: string = this.data.customerId;

    ngOnInit(): void {
        if (this.data.address) {
            this.location = { lat: +this.data.address.latitude, lng: +this.data.address.longitude };
        }
    }

    onAddressOutput(e: FormGroup) {
        this.addressFormGroup = e;
        this.address = e.value;
    }

    onParsedAddress(e: any) {
        const timeout = setTimeout(() => {
            this.address = {
                ...this.address,
                city: e.locality,
                street: e.route ?? e.street_address,
                county: e.sublocality ?? e.sublocality_level_1 ?? e.administrative_area_level_1,
                latitude: e.lat,
                longitude: e.lng,
                postal_code: e.postal_code,
                number: e.number ?? e.street_number,
            };
            clearTimeout(timeout);
        }, 100);
    }

    saveAddress() {
        if (!this.data.address) {
            this.http
                .post<Address>('/api/addresses', { ...this.address, customerId: this.customerId })
                .pipe(take(1))
                .subscribe({
                    next: (address) => {
                        notify('Adresa a fost creata cu success!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        } else {
            this.http
                .patch<Address>('/api/addresses/' + this.data.address.id, { ...this.addressFormGroup.value, customerId: this.customerId })
                .pipe(take(1))
                .subscribe({
                    next: (address) => {
                        notify('Adresa a fost modificata cu success!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        }
    }

    onGeocodeAddress(event: any) {
        this.geocodeAddress = event;
    }
}
