import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { take } from 'rxjs';
import { LegalInfo } from '@webcoffee/interfaces';
import { ToasterService } from '../../../shared/utils/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { LegalInfoFormComponent } from '../../../shared/components/legal-info-form/legal-info-form.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'webcoffee-legal-info-modal',
    templateUrl: './legal-info-modal.component.html',
    styleUrls: ['./legal-info-modal.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, LegalInfoFormComponent, MatButtonModule],
})
export class LegalInfoModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { customerId: string; legalInfo: LegalInfo },
        private http: HttpClient,
        private toastr: ToasterService,
        private dialogRef: MatDialogRef<LegalInfoModalComponent>,
    ) {}

    legalInfo: LegalInfo = this.data.legalInfo;
    legalInfoFormGroup!: FormGroup;
    customerId: string = this.data.customerId;

    onLegalInfoOutput(e: FormGroup) {
        this.legalInfoFormGroup = e;
    }

    saveLegalInfo() {
        if (!this.data.legalInfo) {
            this.http
                .post<LegalInfo>('/api/legal-infos', { ...this.legalInfoFormGroup.getRawValue(), customerId: this.customerId })
                .pipe(take(1))
                .subscribe({
                    next: (address) => {
                        notify('Datele de facturare au fost salvate cu succes!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        } else {
            this.http
                .patch<LegalInfo>('/api/legal-infos/' + this.data.legalInfo.id, { ...this.legalInfoFormGroup.getRawValue(), customerId: this.customerId })
                .pipe(take(1))
                .subscribe({
                    next: (address) => {
                        notify('Datele de facturare au fost modificate cu succes!', 'success');
                        this.dialogRef.close();
                    },
                    error: (err) => this.toastr.error(err.error.message),
                });
        }
    }
}
