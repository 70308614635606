<webcoffee-page-navigator [navigationLinks]="navigationLinks" (action)="onAction($event)"></webcoffee-page-navigator>
<div class="d-flex flex-column">
    <mat-card class="p-2 hoverable" *ngFor="let address of addresses$ | ngrxPush">
        <div class="d-flex align-items-center justify-content-between">
            <span> {{ address.street }} {{ address.number }}, {{ address.postal_code + ',' }} {{ address.county + ',' }} {{ address.city }} </span>
            <div>
                <button mat-icon-button color="accent" (click)="modifyAddress(address)"><mat-icon svgIcon="edit"></mat-icon></button>
                <button mat-icon-button color="warn" (click)="deleteAddress(address)"><mat-icon svgIcon="trash"></mat-icon></button>
            </div>
        </div>
    </mat-card>
</div>
<div class="no-addresses" *ngIf="(addresses$ | ngrxPush)?.length === 0">Nici o adresa disponibila</div>
