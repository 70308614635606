import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { WebcoffeeLinks } from '@webcoffee/interfaces';
import { isEqual } from 'lodash';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { distinctUntilChanged, map, Observable } from 'rxjs';

import { AppStore } from '../../../../app.store';
import { IfPermissionDirective } from '../../../../shared/utils/directives/if-permission.directive';
import { DropdownDirective } from '../../../../shared/utils/directives/dropdown.directive';
import { NotificationsComponent } from '../notifications/notifications.component';
import { BadgeModule } from 'primeng/badge';
import { NotificationsStore } from '../../data-access/notifications.store';

@Component({
    selector: 'webcoffee-admin-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [CommonModule, MenubarModule, MenuModule, IfPermissionDirective, LetDirective, DropdownDirective, NotificationsComponent, BadgeModule, PushPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
    unreadNotifications$: Observable<number>;
    constructor(
        private appStore: AppStore,
        private notificationsStore: NotificationsStore,
    ) {
        this.unreadNotifications$ = this.notificationsStore.getUnreadNotifications;
    }

    mainMenuItems$: Observable<MenuItem[]> = this.appStore.getUser.pipe(
        distinctUntilChanged(isEqual),
        map((user) => {
            let permissions = new Set([]);

            if (user) {
                if (user.role && user.role?.permissions) {
                    permissions = new Set([...permissions.values(), ...user.role.permissions.map((p) => p.key)]);
                }
                if (user.permissions) {
                    permissions = new Set([...permissions.values(), ...user.permissions.map((p) => p.key)]);
                }
            }
            return [
                {
                    label: 'Clienti',
                    routerLink: WebcoffeeLinks.customers,
                    icon: PrimeIcons.USERS,
                    visible: permissions.has('vezi-clienti'),
                },
                {
                    label: 'Produse',
                    icon: PrimeIcons.SHOPPING_BAG,
                    visible:
                        permissions.has('vezi-produse') ||
                        permissions.has('vezi-brand-uri') ||
                        permissions.has('vezi-categorii') ||
                        permissions.has('vezi-seturi-detalii') ||
                        permissions.has('vezi-detalii'),
                    items: [
                        {
                            label: 'Produse',
                            routerLink: WebcoffeeLinks.products,
                            visible: permissions.has('vezi-produse'),
                        },
                        {
                            label: 'Brand-uri',
                            routerLink: WebcoffeeLinks.brands,
                            visible: permissions.has('vezi-brand-uri'),
                        },
                        {
                            label: 'Categorii',
                            routerLink: WebcoffeeLinks.categories,
                            visible: permissions.has('vezi-categorii'),
                        },
                        {
                            label: 'Seturi detalii',
                            routerLink: WebcoffeeLinks.detailSets,
                            visible: permissions.has('vezi-seturi-detalii'),
                        },
                        {
                            label: 'Detalii',
                            routerLink: WebcoffeeLinks.details,
                            visible: permissions.has('vezi-detalii'),
                        },
                    ],
                },
                {
                    label: 'Furnizori',
                    icon: PrimeIcons.BOX,
                    visible: permissions.has('vezi-detalii'),
                    items: [
                        {
                            label: 'Lista furnizori',
                            routerLink: WebcoffeeLinks.providers,
                            visible: permissions.has('vezi-detalii'),
                        },
                        {
                            label: 'Lista produse furnizor',
                            routerLink: WebcoffeeLinks.providerItems,
                            visible: permissions.has('vezi-detalii'),
                        },
                        {
                            label: 'Log-uri furnizori',
                            routerLink: WebcoffeeLinks.providerLogs,
                            visible: permissions.has('vezi-detalii'),
                        },
                    ],
                },
                {
                    label: 'Comenzi si facturi',
                    icon: PrimeIcons.SHOPPING_CART,
                    visible: permissions.has('vezi-comenzi') || permissions.has('vezi-cerere-produse'),
                    items: [
                        {
                            label: 'Comenzi',
                            routerLink: WebcoffeeLinks.orders,
                            visible: permissions.has('vezi-comenzi'),
                        },
                        {
                            label: 'Cerere produse',
                            routerLink: WebcoffeeLinks.productRequests,
                            visible: permissions.has('vezi-cerere-produse'),
                        },
                    ],
                },
                {
                    label: 'Pagini',
                    icon: PrimeIcons.IMAGES,
                    visible: permissions.has('vezi-editor-pagini') || permissions.has('vezi-produse-prima-pagina') || permissions.has('vezi-banere'),
                    items: [
                        {
                            label: 'Editor pagini',
                            routerLink: WebcoffeeLinks.pages,
                            visible: permissions.has('vezi-editor-pagini'),
                        },
                        {
                            label: 'Produse prima pagina',
                            routerLink: WebcoffeeLinks.frontPageProducts,
                            visible: permissions.has('vezi-produse-prima-pagina'),
                        },
                        {
                            label: 'Banere',
                            routerLink: WebcoffeeLinks.banners,
                            visible: permissions.has('vezi-detalii'),
                        },
                    ],
                },
                {
                    label: 'Email-uri',
                    icon: PrimeIcons.SEND,
                    visible: permissions.has('vezi-email-uri'),
                    items: [
                        {
                            label: 'Email-uri',
                            routerLink: WebcoffeeLinks.emails,
                        },
                        {
                            label: 'Liste de distributie',
                            routerLink: WebcoffeeLinks.dls,
                        },
                    ],
                },
                {
                    label: user?.name,
                    visible: !!user,
                    style: {
                        'margin-left': 'auto',
                    },
                    icon: PrimeIcons.USER,
                    items: [
                        {
                            label: user?.role?.name,
                            style: {
                                'font-size': '12px',
                                color: 'black',
                                opacity: 0.75,
                            },
                            disabled: true,
                        },
                        {
                            style: {
                                height: '1px',
                                background: 'rgba(125,125,125,0.25)',
                            },
                            separator: true,
                        },
                        {
                            label: 'Profilul meu',
                            routerLink: WebcoffeeLinks.userProfile,
                            icon: PrimeIcons.ID_CARD,
                        },
                    ],
                },
                // {
                //     // icon: PrimeIcons.BELL,
                //     label: `
                //     `,
                //     escape: false,
                //     styleClass: 'icon-menu-item',
                //     badge: '3',
                //     badgeStyleClass: 'badge-count',
                // },
                {
                    icon: PrimeIcons.COG,
                    styleClass: 'icon-menu-item',
                    items: [
                        {
                            label: 'Utilizatori',
                            routerLink: WebcoffeeLinks.users,
                            icon: PrimeIcons.USERS,
                            visible: permissions.has('vezi-utilizatori'),
                        },
                        {
                            label: 'Roluri si permisiuni',
                            routerLink: WebcoffeeLinks.rolesPermissions,
                            icon: PrimeIcons.PRIME,
                            visible: permissions.has('vezi-roluri-si-permisiuni'),
                        },
                        {
                            label: 'Tools',
                            routerLink: WebcoffeeLinks.tools,
                            icon: PrimeIcons.WRENCH,
                            visible: permissions.has('vezi-tool-uri'),
                        },
                        {
                            label: 'Task-uri',
                            routerLink: WebcoffeeLinks.tasks,
                            icon: PrimeIcons.SERVER,
                            visible: permissions.has('vezi-task-uri'),
                        },
                        {
                            label: 'Redirects',
                            routerLink: WebcoffeeLinks.redirects,
                            icon: PrimeIcons.LINK,
                            visible: permissions.has('vezi-redirects'),
                        },
                        {
                            label: 'Job-uri',
                            routerLink: WebcoffeeLinks.jobs,
                            icon: PrimeIcons.TELEGRAM,
                            visible: permissions.has('vezi-job-uri'),
                        },
                    ],
                },
                {
                    icon: PrimeIcons.SIGN_OUT,
                    styleClass: 'icon-menu-item',
                    command: () => this.appStore.logoutEffect(true),
                },
            ];
        }),
    );
}
