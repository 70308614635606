import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NotificationSeverityEnum, UserNotification, WebcoffeeLinks } from '@webcoffee/interfaces';
import { Observable, debounceTime, distinctUntilChanged, filter, take } from 'rxjs';
import { NotificationsStore } from '../../data-access/notifications.store';
import { MatTableModule } from '@angular/material/table';
import { WebcoffeeDatePipe } from 'apps/admin/src/app/shared/utils/pipes/wc-date.pipe';
import { MatButtonModule } from '@angular/material/button';
import { SimpleLoadingComponent } from 'apps/admin/src/app/shared/ui/simple-loading/simple-loading.component';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { NotificationTypePipe } from './notification-type.pipe';
import { RouterModule } from '@angular/router';
import { SubSink } from 'subsink';
import { isEqual } from 'lodash';

@Component({
    standalone: true,
    selector: 'webcoffee-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    imports: [
        CommonModule,
        PushPipe,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatTableModule,
        LetDirective,
        WebcoffeeDatePipe,
        MatButtonModule,
        SimpleLoadingComponent,
        MatIconModule,
        MatPaginatorModule,
        NotificationTypePipe,
        RouterModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    usersNotifications$: Observable<UserNotification[]>;
    usersNotificationsCount$: Observable<number> = this.notificationsStore.getUsersNotificationsCount;
    query$: Observable<{ skip: number; take: number; search?: string }> = this.notificationsStore.getQuery;

    tableColumns = ['type', 'severity', 'message', 'created_at', 'user', 'action'];
    filterFormGroup = new FormGroup({
        search: new FormControl(''),
    });

    subs = new SubSink();

    NotificationSeverityEnum = NotificationSeverityEnum;

    constructor(private notificationsStore: NotificationsStore) {
        this.usersNotifications$ = this.notificationsStore.getUsersNotifications;
    }

    ngOnInit() {
        this.subs.sink = this.filterFormGroup.controls.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((search) => {
            this.notificationsStore.updateQuery({ search });
            this.notificationsStore.getUsersNotificationsEffect();
        });
        this.notificationsStore.getUsersNotificationsEffect();
    }

    openLink(element: UserNotification) {}

    deleteNotification(element: UserNotification) {
        this.notificationsStore.deleteUserNotificationEffect(element.notification.id);
    }

    markAsRead(element: UserNotification) {
        if (!element.read) {
            this.notificationsStore.markAsReadEffect(element.notification.id);
        }
    }

    markAllAsRead() {
        this.notificationsStore.markAllAsReadEffect();
    }

    deleteAll() {
        this.notificationsStore.deleteAllEffect();
    }

    onPageChange(pagination: PageEvent) {
        this.notificationsStore.updateQuery({ skip: pagination.pageIndex * pagination.pageSize, take: pagination.pageSize });
        this.notificationsStore.getUsersNotificationsEffect();
    }

    getLinkArray(linkInfo: any) {
        const linkArray = [];
        if (!linkInfo || !linkInfo.key) return linkArray;

        linkArray.push(WebcoffeeLinks[linkInfo.key]);

        if (linkInfo.id) {
            linkArray.push(linkInfo.id);
        }

        return linkArray;
    }

    getLinkQueryParams(linkInfo: any) {
        if (linkInfo && linkInfo.params && typeof linkInfo.params == 'object' && !Array.isArray(linkInfo.params)) return linkInfo.params;
        else return {};
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
