import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { LegalInfo, LinkAction, NavigationLinks, WebcoffeeLinks } from '@webcoffee/interfaces';
import { filter, Observable, switchMap, take } from 'rxjs';

import { ConfirmationDialogComponent } from '../../../shared/ui/confirmation-dialog/confirmation-dialog.component';
import { PageNavigatorComponent } from '../../../shared/ui/page-navigator/page-navigator.component';
import { CustomersStore } from '../../data-access/customers.store';
import { LegalInfoModalComponent } from '../../ui/legal-info-modal/legal-info-modal.component';

@Component({
    selector: 'webcoffee-customer-legal-infos',
    templateUrl: './customer-legal-infos.component.html',
    styleUrls: ['./customer-legal-infos.component.scss'],
    standalone: true,
    imports: [CommonModule, PageNavigatorComponent, MatCardModule, MatIconModule, PushPipe, MatButtonModule],
})
export class CustomerLegalInfosComponent implements OnInit {
    constructor(private route: ActivatedRoute, private store: CustomersStore, private dialog: MatDialog) {}

    legalInfos$: Observable<LegalInfo[]> = this.store.getCustomerLegalInfos;
    webcoffeeLinks = WebcoffeeLinks;

    navigationLinks: NavigationLinks = {
        left: [
            {
                link: ['..'],
                text: 'Inapoi',
                icon: 'caret-left',
            },
        ],
        right: [
            {
                icon: 'plus',
                emit: LinkAction.ADD,
                classes: 'accent',
            },
        ],
    };

    ngOnInit(): void {
        this.store.watchRouteParamsEffect(this.route.params);
        this.store.getSelectedCustomer
            .pipe(
                filter((customer) => !!customer),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerLegalInfosEffect());
    }

    onAction(e: LinkAction) {
        if (e === LinkAction.ADD) {
            this.addLegalInfo();
        }
    }

    addLegalInfo() {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(LegalInfoModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerLegalInfosEffect());
    }

    modifyLegalInfo(legalInfo: LegalInfo) {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(LegalInfoModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                                legalInfo,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerLegalInfosEffect());
    }

    deleteLegalInfo(legalInfo: LegalInfo) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    message: 'Esti sigur ca vrei sa stergi aceste date de facturare?',
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
                if (res.confirmed) {
                    this.store.deleteLegalInfoEffect(legalInfo.id);
                }
            });
    }
}
