import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[webcoffeeDropdown]',
    standalone: true,
})
export class DropdownDirective {
    _dropdownID: string | null;
    @Input()
    set dropdownID(value: string | null) {
        this._dropdownID = value;

        if (value) {
            const timeout = setTimeout(() => {
                const el = document.getElementById(value);

                if (el) {
                    el.onmouseenter = () => {
                        this.focused = true;
                    };
                    el.onmouseleave = () => {
                        this.focused = false;
                        this.resetTimer(el);
                    };
                }
                clearTimeout(timeout);
            }, 500);
        }
    }
    get dropdownID() {
        return this._dropdownID;
    }
    @Input()
    boundingRect = false;
    @Input() autoClose = true;
    focused = false;
    timeout: any;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    @HostListener('click', ['$event.target'])
    public onClick(target: any) {
        if (this.dropdownID) {
            const el = this.document.getElementById(this.dropdownID);

            if (el) {
                this.renderer.addClass(el, 'active');
                this.renderer.addClass(this.el.nativeElement, 'active');
                if (this.autoClose) {
                    this.resetTimer(el);
                }
            }
        }
    }

    @HostListener('document:click', ['$event'])
    public onGlobalClick(event: any): void {
        if (this.dropdownID) {
            const el = this.document.getElementById(this.dropdownID);

            if (el) {
                const clickedInsideInput = this.el.nativeElement.contains(event.target);
                const clickedInsideDropdown = el?.contains(event.target);
                let boundingRect = this.boundingRect;

                if (this.boundingRect) {
                    const { clientX, clientY } = event;
                    const { top, bottom, left, right } = el.getBoundingClientRect();
                    boundingRect = clientX > left && clientX < right && clientY > top && clientY < bottom;
                }

                if (!clickedInsideInput && !clickedInsideDropdown && !boundingRect) {
                    this.renderer.removeClass(el, 'active');
                    this.renderer.removeClass(this.el.nativeElement, 'active');
                } else {
                    if (this.autoClose) {
                        this.resetTimer(el);
                    }
                }
            }
        }
    }

    resetTimer(el: HTMLElement) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (!this.focused) {
                this.renderer.removeClass(el, 'active');
                this.renderer.removeClass(this.el.nativeElement, 'active');
            }

            clearTimeout(this.timeout);
        }, 3000);
    }
}
