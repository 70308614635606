<webcoffee-page-navigator [navigationLinks]="navigationLinks" (action)="onAction($event)"></webcoffee-page-navigator>
<div class="d-flex flex-column">
    <mat-card class="p-2 hoverable" *ngFor="let contact of contacts$ | ngrxPush">
        <div class="d-flex align-items-center justify-content-between">
            <div class="contact-details">
                <span>{{ contact.name }}</span>
                <span>{{ contact.email }}</span>
                <span>{{ contact.phone }}</span>
            </div>
            <div>
                <button mat-icon-button color="accent" (click)="modifyContact(contact)"><mat-icon svgIcon="edit"></mat-icon></button>
                <button mat-icon-button color="warn" (click)="deleteContact(contact)"><mat-icon svgIcon="trash"></mat-icon></button>
            </div>
        </div>
    </mat-card>
</div>
<div class="no-contacts" *ngIf="(contacts$ | ngrxPush)?.length === 0">Nici un contact disponibil</div>
