import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Address, LinkAction, NavigationLinks, WebcoffeeLinks } from '@webcoffee/interfaces';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '../../../shared/ui/confirmation-dialog/confirmation-dialog.component';
import { PageNavigatorComponent } from '../../../shared/ui/page-navigator/page-navigator.component';
import { CustomersStore } from '../../data-access/customers.store';
import { AddressModalComponent } from '../../ui/address-modal/address-modal.component';

@Component({
    selector: 'webcoffee-customer-addresses',
    templateUrl: './customer-addresses.component.html',
    styleUrls: ['./customer-addresses.component.scss'],
    standalone: true,
    imports: [CommonModule, PageNavigatorComponent, MatIconModule, MatCardModule, PushPipe, MatButtonModule],
})
export class CustomerAddressesComponent implements OnInit {
    constructor(private route: ActivatedRoute, private dialog: MatDialog, private store: CustomersStore) {}

    addresses$: Observable<Address[]> = this.store.getCustomerAddresses;
    webcoffeeLinks = WebcoffeeLinks;

    navigationLinks: NavigationLinks = {
        left: [
            {
                link: ['..'],
                text: 'Inapoi',
                icon: 'caret-left',
            },
        ],
        right: [
            {
                icon: 'plus',
                emit: LinkAction.ADD,
                classes: 'accent',
            },
        ],
    };

    ngOnInit(): void {
        this.store.watchRouteParamsEffect(this.route.params);
        this.store.getSelectedCustomer
            .pipe(
                filter((customer) => !!customer),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerAddressesEffect());
    }

    onAction(e: LinkAction) {
        if (e === LinkAction.ADD) {
            this.addAddress();
        }
    }

    addAddress() {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(AddressModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerAddressesEffect());
    }

    modifyAddress(address: Address) {
        this.store.getSelectedCustomer
            .pipe(
                switchMap((customer) =>
                    this.dialog
                        .open(AddressModalComponent, {
                            panelClass: 'wc-modal-large',
                            disableClose: true,
                            data: {
                                customerId: customer.id,
                                address,
                            },
                        })
                        .afterClosed(),
                ),
                take(1),
            )
            .subscribe((_) => this.store.getCustomerAddressesEffect());
    }

    deleteAddress(address: Address) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    message: 'Esti sigur ca vrei sa stergi aceasta adresa?',
                },
            })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
                if (res.confirmed) {
                    this.store.deleteAddressEffect(address.id);
                }
            });
    }
}
