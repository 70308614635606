<webcoffee-page-navigator [navigationLinks]="navigationLinks" (action)="onAction($event)"></webcoffee-page-navigator>
<div class="d-flex flex-column">
    <mat-card class="p-2 hoverable" *ngFor="let legalInfo of legalInfos$ | ngrxPush">
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column">
                <span> {{ legalInfo.name }} </span>
                <span>{{ legalInfo.vatCode }}, {{ legalInfo.regCom + ',' }} {{ legalInfo.representative }}</span>
                <span> {{ legalInfo.address?.street }}, {{ legalInfo.address?.details }}, {{ legalInfo.address?.county }}, {{ legalInfo.address?.city }} </span>
            </div>
            <div>
                <button mat-icon-button color="accent" (click)="modifyLegalInfo(legalInfo)"><mat-icon svgIcon="edit"></mat-icon></button>
                <button mat-icon-button color="warn" (click)="deleteLegalInfo(legalInfo)"><mat-icon svgIcon="trash"></mat-icon></button>
            </div>
        </div>
    </mat-card>
</div>
<div class="no-legal-infos" *ngIf="(legalInfos$ | ngrxPush)?.length === 0">Nici o data legala disponibila</div>
