import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { User, UserNotification } from '@webcoffee/interfaces';
import { ToasterService } from '../../../shared/utils/services/toastr.service';

interface NotificationsState {
    usersNotifications: UserNotification[];
    unreadNotifications: number;
    query: { skip: number; take: number; search?: string };
    usersNotificationsCount: number | null;
}

@Injectable({ providedIn: 'root' })
export class NotificationsStore extends ComponentStore<NotificationsState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
    ) {
        super({
            usersNotifications: [],
            unreadNotifications: 0,
            query: { skip: 0, take: 50, search: '' },
            usersNotificationsCount: null,
        });
    }

    readonly getUsersNotifications = this.select((state) => state.usersNotifications);
    readonly getUnreadNotifications = this.select((state) => state.unreadNotifications);
    readonly getQuery = this.select((state) => state.query);
    readonly getUsersNotificationsCount = this.select((state) => state.usersNotificationsCount);

    readonly updateUsersNotifications = this.updater((state, usersNotification: UserNotification) => {
        const usersNotifications = [...state.usersNotifications];
        usersNotifications.unshift(usersNotification);
        this.toastr.info(`Notificare noua de la ${usersNotification.notification.owner}: ${usersNotification.notification.message}`);
        return { ...state, usersNotifications };
    });

    readonly removeFromUsersNotifications = this.updater((state, id: string) => {
        return { ...state, usersNotifications: state.usersNotifications.filter((el) => el.notification.id !== id) };
    });

    readonly markReadFromUsersNotifications = this.updater((state, id: string) => {
        return { ...state, usersNotifications: state.usersNotifications.map((el) => (el.notification.id === id ? { ...el, read: true } : el)) };
    });

    readonly markReadToAllFromUsersNotifications = this.updater((state) => {
        return { ...state, usersNotifications: state.usersNotifications.map((el) => ({ ...el, read: true })) };
    });

    readonly increamentUnreadNotifications = this.updater((state) => {
        return { ...state, unreadNotifications: state.unreadNotifications + 1 };
    });

    readonly decrementUnreadNotifications = this.updater((state) => {
        return { ...state, unreadNotifications: state.unreadNotifications - 1 };
    });

    readonly getUsersNotificationsEffect = this.effect(($) =>
        $.pipe(
            switchMap(() => {
                let decodedParams = new HttpParams();

                for (const [queryKey, queryVal] of Object.entries(this.get().query)) {
                    if (typeof queryVal === 'string') {
                        if (queryVal.trim() !== '') {
                            decodedParams = decodedParams.append(queryKey, queryVal);
                        }
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }
                return this.http.get<{ count: number; items: UserNotification[] }>('/api/notifications/paginate', { params: decodedParams }).pipe(
                    tap((countAndOrders) => {
                        if (countAndOrders) {
                            this.patchState({ usersNotificationsCount: countAndOrders.count, usersNotifications: countAndOrders.items });
                        }
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly deleteUserNotificationEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) => {
                return this.http.delete('/api/notifications/' + id).pipe(
                    tap((_) => {
                        this.removeFromUsersNotifications(id);
                        this.decrementUnreadNotifications();
                        this.getUsersNotificationsEffect();
                        this.toastr.success('Notificarea a fost stearsa');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly markAsReadEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) => {
                return this.http.post('/api/notifications/mark-as-read', { ids: [id] }).pipe(
                    tap((_) => {
                        this.markReadFromUsersNotifications(id);
                        this.decrementUnreadNotifications();
                        this.toastr.success('Notificarea a fost citita');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly markAllAsReadEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.post('/api/notifications/mark-all-as-read', {}).pipe(
                    tap((_) => {
                        this.markReadToAllFromUsersNotifications();
                        this.patchState({ unreadNotifications: 0 });
                        this.toastr.success('Toate notificarile au fost citite');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly deleteAllEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.post('/api/notifications/delete-all', {}).pipe(
                    tap((_) => {
                        this.patchState({ unreadNotifications: 0 });
                        this.getUsersNotificationsEffect();
                        this.toastr.success('Toate notificarile au fost sterse');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...payload };
        if (!query.search || query.search.trim() === '') delete query.search;

        return { ...state, query };
    });
}
