export const productRequestsRoutes = [
    {
        path: '',
        loadComponent: () => import('./feature/product-requests/product-requests.component').then((c) => c.ProductRequestsComponent),
    },
    {
        path: ':id',
        loadComponent: () => import('./feature/product-request-details/product-request-details.component').then((c) => c.ProductRequestDetailsComponent),
    },
];
