import { Routes } from '@angular/router';

export const providerItemsRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./feature/provider-items/provider-items.component').then((c) => c.ProviderItemsComponent),
    },
    {
        path: ':id',
        loadComponent: () => import('./feature/provider-item-details/provider-item-details.component').then((c) => c.ProviderItemDetailsComponent),
    },
    {
        path: ':providerId/:sku',
        loadComponent: () => import('./feature/provider-item-details/provider-item-details.component').then((c) => c.ProviderItemDetailsComponent),
    },
];
