<h3 mat-dialog-title>{{ address ? 'Modifica adresa' : 'Adauga adresa' }}</h3>
<mat-dialog-content>
    <webcoffee-address-form
        [address]="address"
        [customerId]="customerId"
        (addressOutput)="onAddressOutput($event)"
        (geocodeAddress)="onGeocodeAddress($event)"></webcoffee-address-form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-center">
    <button mat-raised-button mat-dialog-close>Anuleaza</button>
    <button mat-raised-button color="primary" [disabled]="!addressFormGroup?.touched || addressFormGroup?.invalid" (click)="saveAddress()">Salveaza adresa</button>
</mat-dialog-actions>
