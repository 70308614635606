<div class="notificaiton-action-row" ngClass.lt-md="flex-column align-items-stretch mb-4">
    <div class="filters" ngClass.lt-md="flex-column" [formGroup]="filterFormGroup">
        <mat-form-field class="mx-2">
            <mat-label>Cauta</mat-label>
            <input matInput type="text" formControlName="search" />
        </mat-form-field>
    </div>
    <div class="d-flex align-items-center" ngClass.lt-md="justify-content-center">
        <button mat-raised-button color="primary" small class="mb-4 mx-2" (click)="markAllAsRead()">Marcheaza toate ca citite</button>
    </div>
    <div class="d-flex align-items-center" ngClass.lt-md="justify-content-center">
        <button mat-raised-button color="primary" small class="mb-4 mx-2" (click)="deleteAll()">Sterge toate</button>
    </div>
</div>
<div class="notification-table-wrapper">
    <div class="table-wrapper" *ngrxLet="usersNotifications$ as usersNotifications">
        <webcoffee-simple-loading message="Incarcare nitificari" *ngIf="!usersNotifications"></webcoffee-simple-loading>
        <table mat-table [dataSource]="usersNotifications" class="webcoffee-table" matSort>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tip</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-bold': !element.read }" class="text-nowrap" (click)="markAsRead(element)">
                    {{ element.notification | notificationType }}
                </td>
            </ng-container>
            <ng-container matColumnDef="severity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" (click)="markAsRead(element)">
                    <div class="d-flex align-items-center">
                        <mat-icon *ngIf="element.notification.severity === NotificationSeverityEnum.Info" class="me-1" svgIcon="circle-info" small info></mat-icon>
                        <mat-icon *ngIf="element.notification.severity === NotificationSeverityEnum.Warning" class="me-1" svgIcon="warning" small warning></mat-icon>
                        <mat-icon *ngIf="element.notification.severity === NotificationSeverityEnum.Critical" class="me-1" svgIcon="rectangle-xmark" small error></mat-icon>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mesaj</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-bold': !element.read }" (click)="markAsRead(element)">
                    <a
                        [routerLink]="getLinkArray(element.notification.linkInfo)"
                        [queryParams]="getLinkQueryParams(element.notification.linkInfo)"
                        (click)="$event.stopPropagation()">
                        {{ element.notification.message }}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-bold': !element.read }" class="text-nowrap" (click)="markAsRead(element)">
                    {{ element.notification.created_at | wcDate: 'dd MMM yy HH:mm' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Utilizator</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'text-bold': !element.read }" (click)="markAsRead(element)">{{ element.notification.owner }}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex align-items-center">
                        <button mat-icon-button small color="warn" (click)="deleteNotification(element)"><mat-icon svgIcon="trash"></mat-icon></button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: tableColumns" [ngStyle]="{ 'background-color': row.read ? '' : 'lightgray' }"></tr>
        </table>
    </div>
</div>
<mat-paginator
    class="notifications-paginator"
    [length]="usersNotificationsCount$ | ngrxPush"
    [pageIndex]="(query$ | ngrxPush)?.skip / 50"
    (page)="onPageChange($event)"
    [pageSize]="50"
    showFirstLastButtons>
</mat-paginator>
