<ng-container *ngrxLet="mainMenuItems$ as mainMenuItems">
    <p-menubar [model]="mainMenuItems">
        <ng-template pTemplate="start">
            <div class="round-logo" routerLink="['/']"></div>
        </ng-template>
        <ng-template pTemplate="end">
            <ul class="p-menubar-root-list">
                <li class="p-element p-menuitem icon-menu-item" webcoffeeDropdown dropdownID="notifications" pBadge value="{{ unreadNotifications$ | ngrxPush }}" severity="danger">
                    <div class="p-menuitem-content">
                        <a pRipple class="p-menuitem-link">
                            <i class="p-menuitem-icon pi pi-bell"></i>
                        </a>
                    </div>
                    <webcoffee-notifications [id]="'notifications'"></webcoffee-notifications>
                </li>
            </ul>
        </ng-template>
    </p-menubar>
</ng-container>
