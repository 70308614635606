import { Routes } from '@angular/router';

export const providerRoutes: Routes = [
    {
        path: '',
        loadComponent: () => import('./feature/providers/providers.component').then((c) => c.ProvidersComponent),
    },
    {
        path: ':id',
        loadComponent: () => import('./feature/provider-details/provider-details.component').then((c) => c.ProviderDetailsComponent),
    },
];
