<div [formGroup]="contactFormGroup">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Nume</mat-label>
                <input matInput type="text" formControlName="name" required />
                <mat-error *ngIf="nameControl?.touched && nameControl?.invalid && nameControl?.errors?.required"> Numele este obligatoriu </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" />
                <mat-error *ngIf="emailControl?.touched && emailControl?.invalid && emailControl?.errors?.email"> Adresa de email nu este valida </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput type="text" formControlName="phone" required />
                <mat-error *ngIf="phoneControl?.touched && phoneControl?.invalid && phoneControl?.errors?.required"> Telefonul este obligatoriu </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <dx-select-box
                [dataSource]="customerOptions"
                height="3.3rem"
                placeholder="Selecteaza client"
                displayExpr="label"
                valueExpr="value"
                formControlName="customerId"
                [readOnly]="customerId ? true : false"
                [searchEnabled]="true"></dx-select-box>
        </div>
    </div>
</div>
