import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';

import { routes } from './app-routing';
import { Loader } from '@googlemaps/js-api-loader';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(BrowserAnimationsModule, MatDialogModule, MatSnackBarModule, NgxGpAutocompleteModule, GoogleMapsModule),
        provideRouter(routes),
        provideStore({
            router: routerReducer,
        }),
        provideRouterStore(),
        provideHttpClient(),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [MatIconRegistry, DomSanitizer],
            multi: true,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 10000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
            },
        },
        {
            provide: MatSnackBarRef,
            useValue: {},
        },
        {
            provide: MAT_SNACK_BAR_DATA,
            useValue: {},
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        ConfirmationService,
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: 'AIzaSyBHQcnYR-SUJF8FlEyoKaN7ZmxSqarcedU',
                libraries: ['places'],
            }),
        },
    ],
};

function initializeAppFactory(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): () => Promise<void> {
    return () =>
        new Promise((resolve) => {
            iconRegistry.addSvgIcon('bars', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/bars-solid.svg'));
            iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg'));
            iconRegistry.addSvgIcon('users', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/users.svg'));
            iconRegistry.addSvgIcon('user-tag', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/user-tag-solid.svg'));
            iconRegistry.addSvgIcon('comments', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/comments-dollar-solid.svg'));
            iconRegistry.addSvgIcon('cubes', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/cubes-solid.svg'));
            iconRegistry.addSvgIcon('money-hand', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/hand-holding-usd-solid.svg'));
            iconRegistry.addSvgIcon('map', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/map-marked-alt-solid.svg'));
            iconRegistry.addSvgIcon('shipping', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shipping-fast-solid.svg'));
            iconRegistry.addSvgIcon('warehouse', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/warehouse-solid.svg'));
            iconRegistry.addSvgIcon('signout', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sign-out-alt-solid.svg'));
            iconRegistry.addSvgIcon('caret-down', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/caret-down-solid.svg'));
            iconRegistry.addSvgIcon('caret-up', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/caret-up-solid.svg'));
            iconRegistry.addSvgIcon('caret-left', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/caret-left-solid.svg'));
            iconRegistry.addSvgIcon('caret-right', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/caret-right-solid.svg'));
            iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-regular.svg'));
            iconRegistry.addSvgIcon('eye-slash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-slash-regular.svg'));
            iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trash-alt-regular.svg'));
            iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/upload.svg'));
            iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus-square.svg'));
            iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit.svg'));
            iconRegistry.addSvgIcon('sun', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sun.svg'));
            iconRegistry.addSvgIcon('moon', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/moon.svg'));
            iconRegistry.addSvgIcon('font', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/font.svg'));
            iconRegistry.addSvgIcon('categories', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/categories.svg'));
            iconRegistry.addSvgIcon('details', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/details.svg'));
            iconRegistry.addSvgIcon('folder', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/folder.svg'));
            iconRegistry.addSvgIcon('tags', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/tags.svg'));
            iconRegistry.addSvgIcon('minus', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/minus.svg'));
            iconRegistry.addSvgIcon('magic', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/magic.svg'));
            iconRegistry.addSvgIcon('columns', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/columns-solid.svg'));
            iconRegistry.addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/download.svg'));
            iconRegistry.addSvgIcon('object-group', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/object-group.svg'));
            iconRegistry.addSvgIcon('success', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/success.svg'));
            iconRegistry.addSvgIcon('warning', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/warning.svg'));
            iconRegistry.addSvgIcon('error', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/error.svg'));
            iconRegistry.addSvgIcon('s', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/s-solid.svg'));
            iconRegistry.addSvgIcon('3-dots', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/3-dots.svg'));
            iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/search.svg'));
            iconRegistry.addSvgIcon('front-page', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/front-page.svg'));
            iconRegistry.addSvgIcon('emails', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/emails.svg'));
            iconRegistry.addSvgIcon('mail', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/mail.svg'));
            iconRegistry.addSvgIcon('dl', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/dl.svg'));
            iconRegistry.addSvgIcon('cancel', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/cancel.svg'));
            iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/calendar.svg'));
            iconRegistry.addSvgIcon('gear', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gear.svg'));
            iconRegistry.addSvgIcon('reload', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/reload.svg'));
            iconRegistry.addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/bell.svg'));
            iconRegistry.addSvgIcon('bell-slash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/bell-slash.svg'));
            iconRegistry.addSvgIcon('person-circle-question', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/person-circle-question.svg'));
            iconRegistry.addSvgIcon('list-check', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/list-check.svg'));
            iconRegistry.addSvgIcon('link', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/link.svg'));
            iconRegistry.addSvgIcon('repeat', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/repeat.svg'));
            iconRegistry.addSvgIcon('copyright', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/copyright.svg'));
            iconRegistry.addSvgIcon('invoice-dollar', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/invoice-dollar.svg'));
            iconRegistry.addSvgIcon('card-success', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/card-success.svg'));
            iconRegistry.addSvgIcon('card-error', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/card-error.svg'));
            iconRegistry.addSvgIcon('card-pending', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/card-pending.svg'));
            iconRegistry.addSvgIcon('print', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/print.svg'));
            iconRegistry.addSvgIcon('easybox', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/box-open-solid.svg'));
            iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eye-solid.svg'));
            iconRegistry.addSvgIcon('arrow-up-right-square', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-up-right-square.svg'));
            iconRegistry.addSvgIcon('circle-info', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/circle-info.svg'));
            iconRegistry.addSvgIcon('rectangle-xmark', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/rectangle-xmark.svg'));
            iconRegistry.addSvgIcon('ban', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/ban-solid.svg'));

            resolve();
        });
}
